import { Box, Flex } from '@chakra-ui/react'
import Helmet from 'react-helmet'

function TermsAndConditions() {
	return (
		<Flex direction='column' height='100vh'>
			<Helmet>
				<title>Valence Intelligence | Terms and Conditions</title>
			</Helmet>
			<Box flex='1'>
				<iframe
					title='Document Viewer'
					src={import.meta.env.VITE_TERMS_AND_CONDITIONS_URL}
					width='100%'
					height='100%'
					style={{ border: 'none' }}
				/>
			</Box>
		</Flex>
	)
}

export default TermsAndConditions
